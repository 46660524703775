.ipad_layout {
    font-size: 12px;
    font-family: SimSun, Arial;
    margin: 0 auto;
    width: 1024px;
}

.layout {
    position: relative;
}

.headAd {
    width: 1000px;
    /* height: 90px; */
    overflow: hidden;
    text-align: center;
    margin: 0 auto 25px;
}

.section {
    display: block;
    margin-top: 18px;
    transition: all 0.5s;
    width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 70;
}

.bottomAd {
    width: 1000px;
    /* height: 90px; */
    overflow: hidden;
    text-align: center;
}

.slideSwitch{
    width: 150px;
    position: absolute;
    top: 300px;
    left: 0;
}