.brands {
    width: 100%;
}
.titleWrapper {
    width: 100%;
    height: 42px;
    background-color: #f7f7f7;
    position: relative;
}
.title_logo {
    position: absolute;
    width: 6px;
    height: 20px;
    left: 10px;
    top: 12px;
}
.title {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
    color: #222222;
    line-height: 42px;
    margin-right: 30px;
    float: left;
}
.linkList {
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    color: #222222;
    line-height: 42px;
    float: left;
    & li {
        float: left;
        position: relative;
        & a {
            color: #222222;
        }
        & span {
            margin: 0 8px;
        }
    }
}
.list {
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.listItem {
    width: 198px;
    margin-right: 28px;
    margin-bottom: 28px;
    float: left;
    height: 198px;
    overflow: hidden;
    & a:hover {
        color: #f54343;
        text-decoration: none;
        & .summary {
            color: #f54343;
        }
    }
}
.imgWrapper {
    width: 100%;
    height: 112px;
    overflow: hidden;
    position: relative;
    & img {
        width: 100%;
    }
}
.playNum {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 29px;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
}
.inner {
    padding-top: 7px;
    padding-left: 22px;
    background: url(http://p3.ifengimg.com/a/2017/1129/shape127.png) no-repeat 10px 10px;
    color: #fff;
    font-size: 12px;
}
.btnWrapper {
    width: 100%;
    height: 50px;
    position: relative;
}
.btn {
    margin-top: 3px;
    width: 210px;
    height: 43px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -105px;
    cursor: pointer;
}
.summary {
    margin-top: 5px;
    line-height: 24px;
    font-size: 12px;
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 44px;
    text-decoration: none;
    color: rgb(34, 34, 34);
}
.info {
    font-size: 14px;
    color: #999999;
    font-family: 'Microsoft YaHei';
    margin-top: 9px;
}
.author_name {
    width: 112px;
    overflow: hidden;
    float: left;
}
.author_date {
    float: right;
}
