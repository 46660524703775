.topicTitleSmall {
    width: 300px;
    height: 42px;
    overflow: hidden;
    background-color: #f7f7f7;
    position: relative;
}
.title_logo {
    position: absolute;
    width: 6px;
    height: 20px;
    left: 10px;
    top: 12px;
    & img {
        width: 100%;
    }
}
.title {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
    color: #222222;
    line-height: 42px;
    margin-right: 30px;
    float: left;
}
