.brandsTitle {
    width: 100%;
}
.titleWrapper {
    width: 100%;
    height: 42px;
    background-color: #f7f7f7;
    position: relative;
}
.title_logo {
    position: absolute;
    width: 6px;
    height: 20px;
    left: 10px;
    top: 12px;
}
.title {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Microsoft YaHei';
    color: #222222;
    line-height: 42px;
    margin-right: 30px;
    float: left;
}
.linkList {
    font-size: 14px;
    font-family: 'Microsoft YaHei';
    color: #222222;
    line-height: 42px;
    float: left;
    & li {
        float: left;
        position: relative;
        & a {
            color: #222222;
        }
        & span {
            margin: 0 8px;
        }
    }
}
