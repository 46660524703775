.subhead {
    display: block;
    width: 100%;
}
.headerBox {
    width: 1000px;
    margin: 0 auto;
    height: 43px;
}
.logo {
    display: inline;
    float: left;
    width: 164px;
    height: 43px;
    overflow: hidden;
    font-size: 100%;
    font-weight: normal;
    & a {
        display: block;
        width: 164px;
        height: 43px;
        background: url(http://p3.ifengimg.com/a/2017/0905/855e360a5c5e897size9_w164_h43.jpg) no-repeat;
        background-position: 0 0;
        text-indent: -99999px;
    }
}
.headerDown {
    margin: 2px 0 0 5px;
    float: left;
    width: 194px;
    height: 37px;
    overflow: hidden;
}
.searchBox {
    width: 300px;
    float: left;
    padding-top: 8px;
}
.nav {
    min-width: 1000px;
    width: 100%;
    height: 40px;
    background: #f54343;
    margin-top: 13px;
    font-family: 'Microsoft YaHei', 'SimHei', Arial, Helvetica, sans-serif;
    font-size: 16px;
    position: relative;
    z-index: 9;
}
.navBox {
    width: 1000px;
    margin: 0 auto;
}
.navItem,
.more {
    float: left;
    width: 63px;
    height: 40px;
    text-align: center;
    color: #fff;
    overflow: hidden;
    padding: 0 6px;
    line-height: 40px;
    position: relative;
    & a {
        display: block;
        width: 63px;
        height: 40px;
        line-height: 40px;
        color: #fff;
    }
}
.more {
    cursor: pointer;
}
.more_open {
    background: #f2f2f2;
    color: #f54343;
}
.triangle_down {
    width: 10px;
    height: 6px;
    background: url(http://p3.ifengimg.com/a/2017/0117/vhome2016bg0520170107.png) no-repeat;
    background-position: -12px 0;
    position: absolute;
    right: 7px;
    top: 17px;
}
.triangle_up {
    width: 10px;
    height: 6px;
    background: url(http://p3.ifengimg.com/a/2017/0117/vhome2016bg0520170107.png) no-repeat;
    position: absolute;
    right: 7px;
    top: 17px;
    background-position: 0 0;
}
.current {
    background: #f2f2f2;
    & a {
        color: #f54343;
    }
}
.navFold {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: #f2f2f2;
    display: none;
    cursor: default;
    z-index: 99;
    transition: opacity 0.3s;
}
.navFoldBox {
    width: 1000px;
    margin: 0 auto;
    overflow: hidden;
    zoom: 1;
    padding: 4px 0 12px 0;
}
.navFoldBoxInner {
    width: 100%;
    height: 64px;
    & li {
        line-height: 25px;
        margin-top: 7px;
        float: left;
        width: 63px;
        height: 25px;
        padding: 0 6px;
        text-align: center;
        & a {
            color: #222222;
        }
    }
}
