.brandsLeagueList {
    width: 100%;
}
.listItem {
    margin-top: 17px;
    & a {
        display: block;
        width: 100%;
    }
    & a:hover .brands_content {
        color: #f54343;
    }
}
.brands_logo {
    float: left;
    width: 160px;
    height: 36px;
    & img {
        display: block;
        width: 100%;
    }
}
.brands_content {
    width: 124px;
    color: #222222;
    line-height: 20px;
    font-size: 14px;
    float: right;
    text-decoration: none;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
}
