.box {
    width: 1000px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
}

.footer {
    width: 1000px;
    height: 72px;
    margin: 35px auto;
    clear: both;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    color: #333;
}
.right_link {
    float: right;
    & ul li {
        text-align-last: left;
        & a {
            color: #333;
        }
    }
}
.f_ico1 {
    padding-left: 23px;
    background: url(http://y1.ifengimg.com/5887c5da2472fe29/2015/23/footer.png) no-repeat 0 5px;
}
.v_dellink {
    padding-left: 23px;
    text-align: left;
    background: url(http://y2.ifengimg.com/981b45cfeffdba6a/2015/38/v_dellink.jpg) no-repeat 1px 9px;
}
