.content {
    width: 1000px;
}
.top_content {
    width: 1000px;
    margin: 0 auto;
}
.leftPart {
    width: 650px;
    background: #fff;
    float: left;
    margin-top: 40px;
}
.rightPart {
    width: 300px;
    float: right;
    margin-top: 40px;
    background: #fff;
}

.ad {
    width: 100%;
    height: 90px;
    background: #ccc;
}

.rectangle01Ad {
    margin-top: 25px;
    width: 100%;
}
.rectangle02Ad {
    /* margin-top: 50px; */
    width: 100%;
}
.rectangle03Ad {
    margin-top: 20px;
    width: 100%;
}

.feedBox {
    border-top: 3px solid #f7f7f7;
    margin: 30px 0;
}
