.hotTopic {
    width: 100%;
    margin-top: 30px;
}
.hotTopicList {
    width: 100%;
}
.listItem {
    display: block;
    padding: 10px 0 6px;
    & a {
        display: block;
    }
    & a:hover {
        text-decoration: none;
        & .title {
            color: #f54343;
            text-decoration: none;
        }
        & .maskWrapper {
            display: block;
        }
    }
}
.img {
    width: 300px;
    height: 168px;
    position: relative;
    & img {
        display: block;
        width: 100%;
    }
}
.maskWrapper {
    position: absolute;
    width: 300px;
    height: 168px;
    left: 0;
    top: 0;
    display: none;
}
.mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 1;
    opacity: 0.3;
    filter: alpha(opacity=30);
    _filter: alpha(opacity=30);
    -moz-opacity: 0.3;
}
.purse {
    position: absolute;
    margin-left: -15px;
    margin-top: -15px;
    left: 50%;
    top: 50%;
    display: block;
    width: 31px;
    height: 31px;
    background: url(//p3.ifengimg.com/a/2017/1129/vedio_play.png) no-repeat 0 0;
    /* _background: 0; */
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='//p3.ifengimg.com/a/2017/1129/vedio_play.png',sizingMethod='scale')";
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='//p3.ifengimg.com/a/2017/1129/vedio_play.png',sizingMethod='scale');
    z-index: 2;
}
.title {
    display: block;
    width: 100%;
    padding-top: 4px;
    height: 25px;
    line-height: 25px;
    overflow: hidden;
    font-size: 16px;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #222222;
    text-decoration: none;
}
