.slider {
    width: 100%;
    position: relative;
}
.slideContaniner {
    width: 720px;
    height: 416px;
}
.slideItem {
    & a {
        display: block;
        height: 100%;
        & img {
            display: block;
        }
    }
}
[ifeng_ui_pc_sliders-dots] {
    width: 280px;
    height: 416px !important;
    right: 0px !important;
    left: 100% !important;
    bottom: 0 !important;
    top: 0px;
}
.dot {
    /* box-sizing: border-box; */
    display: block !important;
    width: 280px !important;
    height: 83px !important;
    background: #090704 !important;
    position: relative;
    &:hover {
        opacity: 1 !important;
    }
}
.current {
    /* box-sizing: border-box; */
    display: block !important;
    width: 300px !important;
    height: 83px !important;
    position: relative;
    left: -20px;
    background: url(//p3.ifengimg.com/a/2017/1129/hover_bg.png) no-repeat !important;
    background-size: cover !important;
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='//p3.ifengimg.com/a/2017/1129/hover_bg.png',sizingMethod='scale')";
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='//p3.ifengimg.com/a/2017/1129/hover_bg.png',sizingMethod='scale');
    & .sliderItem {
        padding-left: 20px;
        border-bottom: 0;
        & .innerText {
            left: 20px;
        }
    }
    &:hover {
        opacity: 1 !important;
    }
}
.sliderItem {
    width: 280px;
    height: 83.2px;
    border-bottom: 1px solid #2d2d2d;
    position: relative;
}
.innerText {
    padding-top: 17.75px;
    padding-left: 20px;
    width: 240px;
    height: 65.75px;
    line-height: 24px;
    text-align: left;
    position: absolute;
    left: 0;
    & a {
        display: -webkit-box;
        width: 240px;
        height: 48px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: #fff;
        font-size: 14px;
    }
    & a:hover {
        text-decoration: none;
    }
}
